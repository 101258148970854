/* You can add global styles to this file, and also import other style files */
@import 'ui-kit';

.main-grid-wrapper {
  // height: 720px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // min-height: 400px;
}

.material-symbols-outlined.filled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.inner-grid-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 400px;
}

.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.do-form-field {
  margin-bottom: 2rem !important;
}
.action-button {
  margin-left: 0.5rem !important;
}
.action-container,
.action-button-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > * {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

.iframe-modal {
  .fullscreen-modal {
    max-width: calc(100% - var(--bs-modal-margin));
  }

  > .modal-dialog > .modal-content {
    height: 92vh;
  }
}
