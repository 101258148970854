@import 'variables';
@import 'bootstrap-minimal';
@import 'ag-grid';
@import 'ng-select';
@import 'ngx-toastr';

$dts-primary: $primary;
$selected-item-border-color: $primary;
$box-shadow: false;
$range-start-border: false;
$selected-item-border-size: 2px;

@import 'ngx-drag-to-select';

html,
body {
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light;
  border-radius: 0.25rem;
}

::-webkit-scrollbar {
  width: 0.425rem;
  height: 0.425rem;
}

h6 {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-125 {
  font-size: 1.25rem;
}

.fs-150 {
  font-size: 1.5rem;
}

.fs-0875 {
  font-size: 0.875rem;
}

.fs-200 {
  font-size: 2rem;
}

.material-symbols-outlined.filled {
  font-variation-settings: 'FILL' 1;
}

.rotate-180 {
  transform: rotate(180deg);
}

.btn-secondary,
.btn-outline-secondary {
  color: $primary;

  &:hover,
  &:active,
  &:focus,
  &:active,
  &:focus-within {
    color: $primary !important;
  }
}

.btn.btn-link {
  font-weight: 600;
}

.shadow-custom {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.15);
}

.shimmer {
  overflow: hidden;
  position: relative;
  background-color: #ddd;
}

.shimmer::before {
  content: '';
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.color-picker .saturation-lightness {
  height: 90px !important;
}

.popover {
  box-shadow: $popover-box-shadow;
}
