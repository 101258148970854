@use '@ag-grid-community/styles' as ag;
@import 'variables';
@include ag.grid-styles(
  (
    theme: dewert-okin,
    extend-theme: quartz,
    active-color: $primary,
    odd-row-background-color: rgba($primary, 0.06),
    row-border-color: white,
    row-border-width: 0.125rem,
    background-color: rgba($primary, 0.02),
    header-background-color: white,
    font-family: $font-family-base,
    header-column-resize-handle-height: 100%,
    header-column-resize-handle-width: 1px,
    font-size: $font-size-base,
    data-color: $dark,
    border-color: $border-color,
    header-foreground-color: $dark,
  )
);
@include ag.grid-styles(
  (
    theme: pippo,
    extend-theme: quartz,
    active-color: $primary,
    odd-row-background-color: rgba($primary, 0.06),
    row-border-color: white,
    row-border-width: 0.125rem,
    background-color: rgba($primary, 0.02),
    header-background-color: white,
    font-family: $font-family-base,
    header-column-resize-handle-height: 50%,
    header-column-resize-handle-width: 1px,
    font-size: $font-size-base,
    data-color: $dark,
    borders: none,
    header-foreground-color: $dark,
  )
);
ag-grid-angular {
  // min-height: 500px;
  // height: 500px;
  flex-grow: 1;

  .ag-floating-filter-full-body {
    overflow: visible;
  }
}
