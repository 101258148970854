@import 'ngx-toastr/toastr';

.toast-container .ngx-toastr {
  padding: 1rem;

  &.toast-success {
    background-color: $success;
    background-image: none;
    color: $dark;
  }

  &.toast-warning {
    background-color: $warning;
    background-image: none;
    color: $dark;
  }

  &.toast-error {
    background-color: $danger;
    background-image: none;
    color: $dark;
  }
}
