$primary: #b900ff;
$secondary: lighten($primary, 45);
$danger: #fc1a38 !default;
$success: #00c278 !default;
$warning: #ffb411 !default;

$gray-200: #f7f7f7 !default;
$gray-400: #e0e0e0 !default;
$gray-600: #717171 !default;
$gray-900: #1e1e1e !default;

$dark: $gray-900 !default;
$light: $gray-600 !default;

$line-height-base: 1.25;

$border-color: $gray-400;
$link-hover-decoration: none;
$link-decoration: none;

$btn-border-width: 0.125rem;

$body-color: $gray-900;
$input-border-color: $border-color;
$font-family-base: 'DM Sans', sans-serif;
$font-size-base: 1rem;
$input-btn-padding-x: 0.75rem;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-y-lg: 0.75rem;
$btn-border-radius: 0.75rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-disabled-opacity: 0.4;
$btn-font-weight: 600;
$font-size-lg: $font-size-base;
$border-radius: 0.25rem;
$input-disabled-bg: $gray-400;
$input-btn-focus-width: 0.2rem;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.1);
$input-disabled-color: rgba($dark, 0.7);
$input-focus-border-color: $border-color;

$pagination-border-color: $border-color;
$pagination-disabled-color: rgba($primary, 0.5);
$pagination-disabled-bg: transparent;
$pagination-border-width: 0;
$pagination-hover-bg: transparent;
$pagination-bg: transparent;
$pagination-active-bg: transparent;
$pagination-active-color: $primary;
$pagination-hover-border-color: transparent;
$pagination-focus-box-shadow: none;
$pagination-focus-bg: transparent;
$pagination-padding-y: 0.125rem;

$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-color: white;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0.5rem;
$enable-negative-margins: true;
$modal-inner-padding: 1rem;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$accordion-button-color: $primary;
$accordion-border-color: white;
$accordion-border-width: 2px;
$accordion-icon-color: $primary;
$accordion-button-active-color: $primary;
$accordion-bg: $gray-200;
$accordion-body-padding-y: 0.5rem;
$accordion-padding-y: 0.5rem;

$popover-border-color: transparent;
$popover-box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.12);
