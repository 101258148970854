@import '@ng-select/ng-select/themes/default.theme.css';

.ng-select {
  flex-grow: 1;
  width: 100%;

  .ng-select-container {
    height: (
      (2 * $input-btn-padding-y) + 0.125 + $font-size-base + 0.5
    ) !important;
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    color: $body-color;

    .ng-value-container {
      padding-left: $input-btn-padding-x;
    }
  }

  &.ng-select-opened .ng-select-container {
    border-color: $input-border-color;
  }
  &.is-invalid .ng-select-container {
    border-color: $danger;
  }
  &.ng-select-disabled .ng-select-container {
    background-color: $input-disabled-bg;

    .ng-value-container .ng-value {
      // opacity: 0.6;
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
      border-color: lighten($primary, 25%);
    }
  }
}

.ng-dropdown-panel {
  z-index: 1056 !important;
  border-color: $input-border-color;
  .ng-option-marked {
    background-color: rgba($primary, 0.06) !important;
  }
  .ng-option-selected {
    background-color: rgba($primary, 0.25) !important;
  }
}
